import React from "react";
import Layout from "../components/Layout";
import { Seo } from "../base/Seo";

export default function Privacy() {
  return (
    <>
      <Seo title="Privacy policy" description="Privacy policy description" />

      <Layout>
        <div className="section">wip Privacy page</div>
      </Layout>
    </>
  );
}
